import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import dndManager from '@common/utils/dnd-manager';

import List from '@common/components/list/pure';
import Confirm from '@common/components/confirm-button';
import { Button } from '@common/components/button';
import { Icon } from '@common/components/icon';
import Dropdown from '@common/components/dropdown/dropdown';
import { QuestionIcon } from '../question-icon';
import { QuestionDropdown } from '../question-dropdown';
import { EQuestionTypes } from '../../definitions';

import type { Screen, ScreenItem } from '../../../learning/types/objects';
import type { ObjectValues } from '@common/types/util-types';
import { combineClassNames } from '@common/utils/combineClassNames';
import { difference, isEqual, sortBy } from 'lodash';

type QuestionItemOwnProps = {
  screenId: string;
  item: Screen;
  disabled?: boolean;
  onOpen: (screenId: string) => void;
  onDuplicate: (screen: ScreenItem) => void;
  onDelete: (screenId: string) => void;
  onDrag: (sourceId: string, targetIndex: number) => void;
};

const QuestionItem = ({
  item,
  screenId,
  disabled = false,
  onOpen,
  onDuplicate,
  onDelete,
  onDrag,
}: QuestionItemOwnProps) => {
  const rootRef = React.useRef(null);
  const { t } = useTranslation();

  const [, dropRef] = useDrop({
    accept: ['question'],
    hover: (source: { id: string }) => {
      if (source.id === item.id) return;

      onDrag(source.id, item.index);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'question',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // @ts-expect-error
  const question = item.components.find((component) => Object.values(EQuestionTypes).includes(component.type));
  const selected = item.id === screenId;

  return (
    <div
      role="listitem"
      // @ts-expect-error
      ref={dropRef(previewRef(rootRef))}
      className={combineClassNames('Question__Item', {
        'Question__Item--selected': selected,
        'Question__Item--disabled': disabled,
      })}
      onClick={() => !disabled && onOpen(item.id)}
      style={((!disabled || selected) && { opacity: isDragging ? 0 : 1 }) || undefined}
    >
      <div ref={(!disabled && dragRef) || undefined} className="Question__Item__Drag">
        <Icon type="drag_handle" />
      </div>
      <QuestionIcon type={question ? question.type : EQuestionTypes.CONTENT} />
      <div className="Question__item__Title">
        {question
          ? question.parameters?.text || <small><Trans i18nKey="survey:no_question" /></small>
          : <Trans i18nKey="survey:content_module" />}
      </div>
      {selected && disabled && <Icon type="warning" color="red" />}
      <Dropdown
        alignRight
        id="survey"
        toggle={<Icon type="more_vert" className="Question__Item__More" />}
      >
        {!disabled && (
          <Dropdown.Item onClick={() => onDuplicate(item)}>
            <Trans i18nKey="survey:survey_actions_duplication_question" />
          </Dropdown.Item>
        )}
        {!disabled && <Dropdown.Divider />}
        {(!disabled || selected) && (
          <Confirm
            title={t('survey:survey_actions_delete_title')}
            description={t('survey:survey_actions_delete_description')}
            onConfirm={() => onDelete(item.id)}
          >
            <Dropdown.Item danger>
              <Trans i18nKey="survey:survey_actions_delete_button" />
            </Dropdown.Item>
          </Confirm>
        )}
      </Dropdown>
    </div>
  );
};

type EnabledComponentsArray = React.ComponentProps<typeof QuestionDropdown>['enabledComponents'];

type QuestionsListProps = {
  screens: ScreenItem[];
  screenId: string | undefined;
  disabled?: boolean;
  addQuestion: (type: ObjectValues<typeof EQuestionTypes>) => void;
  saveOrder: (order: string[]) => void;
  handleDrag: (screenId: string, order: string[]) => void;
  deleteQuestion: QuestionItemOwnProps['onDelete'];
  openQuestion: QuestionItemOwnProps['onOpen'];
  duplicateQuestion: QuestionItemOwnProps['onDuplicate'];
  enabledComponents: EnabledComponentsArray;
};

export const QuestionsList = ({
  screens = [],
  screenId,
  disabled = false,
  addQuestion,
  duplicateQuestion,
  saveOrder,
  deleteQuestion,
  openQuestion,
  handleDrag,
  enabledComponents,
}: QuestionsListProps) => {
  const getOrder = useCallback(() => {
    return sortBy(screens, ['index']).map((screen) => screen.id);
  }, [screens]);

  const [order, setOrder] = useState<string[]>(getOrder);

  useEffect(() => {
    const newOrder = getOrder();
    setOrder((currentOrder) => {
      if (newOrder.length > currentOrder.length) {
        return [...currentOrder, newOrder[newOrder.length - 1]];
      }
      if (currentOrder.length > newOrder.length) {
        return currentOrder.filter((id) => id !== difference(currentOrder, newOrder)[0]);
      }
      return currentOrder;
    });
  }, [screens.length, setOrder]);

  const handleSaveNewOrder = useCallback(() => {
    const newOrder = getOrder();
    saveOrder(newOrder);
    setOrder(newOrder);
  }, [setOrder, saveOrder, getOrder]);

  const shouldShowSaveOrder = useMemo(() => {
    return !isEqual(order, getOrder());
    }, [order, getOrder]);

  return (
    <DndProvider manager={dndManager}>
      <List
        items={screens}
        renderRow={QuestionItem as any}
        rowProps={{
          screenId,
          disabled,
          onDelete: deleteQuestion,
          onOpen: openQuestion,
          onDuplicate: duplicateQuestion,
          onDrag: handleDrag,
        }}
        footer={(
          <div className="QuestionsList">
            <QuestionDropdown
              disabled={disabled}
              enabledComponents={enabledComponents}
              // @ts-expect-error
              onSelect={addQuestion}
            />
            {shouldShowSaveOrder && (
              <Button type="inverted-primary" disabled={disabled} onClick={handleSaveNewOrder}>
                <Trans i18nKey="survey:save_question_order" />
              </Button>
            )}
          </div>
        )}
      />
    </DndProvider>
  );
};
