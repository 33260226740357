import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useEncodedFullPath = () => {
  const { pathname, search } = useLocation();
  return encodeURIComponent(pathname + search);
};

export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useQueryParam(paramKey: string) {
  const query = useQuery();
  return useMemo(() => query.get(paramKey), [query, paramKey]);
}
