import React from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleInput } from '@common/components/form/inputs/toggle';
import { Row, Group, TextInput } from '@common/components/form';

type Props = {
  enableDescription?: boolean;
};

export const NumberQuestionInput = ({ enableDescription }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Group>
          <TextInput name="parameters.text" placeholder={t('survey:question_form_question_placeholder')} />
        </Group>
      </Row>
      {enableDescription && (
        <Row>
          <Group>
            <TextInput
              name="parameters.description"
              placeholder={t('survey:question_form_description_placeholder')}
              maxLength={160}
            />
          </Group>
        </Row>
      )}
    </>
  );
};

export const NumberQuestionSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Group>
          <ToggleInput
            helpIcon
            reversed
            name="parameters.answer_required"
            label={t('survey:question_form_answer_is_required')}
          />
        </Group>
      </Row>
      <Row>
        <Group>
          <ToggleInput
            helpIcon
            reversed
            name="parameters.allow_decimals"
            label={t('survey:question_form_decimal_is_allowed')}
          />
        </Group>
      </Row>
    </>
  );
};
