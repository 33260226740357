import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formValues, change, FormName } from 'redux-form';

import type { ScreenComponentItem } from '../../../../../learning/types/objects';

import { Icon } from '@common/components/icon';
import { ToggleInput } from '@common/components/form/inputs/toggle';
import { TextInput, Row, Group } from '@common/components/form';
import { ButtonGroup, ButtonGroupValue } from '@common/components/button-group';
import { Button } from '@common/components/button';
import { EComponentTypes } from '../../../../../learning/definitions';

type FormValues = Extract<ScreenComponentItem, { type: typeof EComponentTypes.QUESTION_DATE_TIME }>;

type Props = {
  enableDescription?: boolean;
};

export const DateTimeQuestionInput = ({ enableDescription }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Group>
          <TextInput name="parameters.text" placeholder={t('survey:question_form_question_placeholder')} />
        </Group>
      </Row>
      {enableDescription && (
        <Row>
          <Group>
            <TextInput
              name="parameters.description"
              placeholder={t('survey:question_form_description_placeholder')}
              maxLength={160}
            />
          </Group>
        </Row>
      )}
    </>
  );
};

enum DateTimeTypeBooleanFields {
  AllowDateSelect = 'parameters.allow_date_select',
  AllowTimeSelect = 'parameters.allow_time_select',
  AllowDateTimeSelect = 'parameters.allow_date_time_select',
}

type DateTimeQuestionFormBoolFields = 'parameters.allow_date_select' | 'parameters.allow_time_select';

// connects fields from current form to the component props
const formConnector = formValues<FormValues>('parameters');

const UnconnectedDateTimeQuestionSettings: Parameters<typeof formConnector>[0] = ({ parameters }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  type ChangeFn = (field: DateTimeQuestionFormBoolFields, value: any) => void;
  const handleChangeValueTypes = (changeFn: ChangeFn) => (newValue: ButtonGroupValue) => {
    if (newValue === DateTimeTypeBooleanFields.AllowDateTimeSelect) {
      changeFn(DateTimeTypeBooleanFields.AllowDateSelect, true);
      return changeFn(DateTimeTypeBooleanFields.AllowTimeSelect, true);
    }

    if (newValue === DateTimeTypeBooleanFields.AllowDateSelect) {
      changeFn(DateTimeTypeBooleanFields.AllowTimeSelect, false);
      return changeFn(DateTimeTypeBooleanFields.AllowDateSelect, true);
    }

    if (newValue === DateTimeTypeBooleanFields.AllowTimeSelect) {
      changeFn(DateTimeTypeBooleanFields.AllowDateSelect, false);
      return changeFn(DateTimeTypeBooleanFields.AllowTimeSelect, true);
    }
  };

  const active = ((): DateTimeTypeBooleanFields | undefined => {
    if (parameters.allow_time_select && parameters.allow_date_select) {
      return DateTimeTypeBooleanFields.AllowDateTimeSelect;
    }

    if (parameters.allow_time_select) return DateTimeTypeBooleanFields.AllowTimeSelect;
    if (parameters.allow_date_select) return DateTimeTypeBooleanFields.AllowDateSelect;
  })();

  // FormName wrapper for getting the name of the current form
  return (
    <FormName>
      {({ form }) => (
        <>
          <div className="DateTimeQuestionSettings__TypePicker">
            <div className="QuestionForm__ValueLabel">
              <span>{t('survey:question_type_values_label')}</span>
              <Icon type="help__filled" />
            </div>

            <ButtonGroup
              onChange={handleChangeValueTypes((field, value) => dispatch(change(form, field, value)))}
              active={active}
            >
              <Button value={DateTimeTypeBooleanFields.AllowDateSelect}>
                {t('survey:question_type_date_time_date_checkbox_label')}
              </Button>
              <Button value={DateTimeTypeBooleanFields.AllowTimeSelect}>
                {t('survey:question_type_date_time_time_checkbox_label')}
              </Button>
              <Button value={DateTimeTypeBooleanFields.AllowDateTimeSelect}>
                {t('survey:question_type_date_time_datetime_checkbox_label')}
              </Button>
            </ButtonGroup>

          </div>

          <Row>
            <Group>
              <ToggleInput
                helpIcon
                reversed
                name="parameters.answer_required"
                label={t('survey:question_form_answer_is_required')}
              />
            </Group>
          </Row>
        </>
      )}
    </FormName>
  );
};

export const DateTimeQuestionSettings = formConnector(UnconnectedDateTimeQuestionSettings);
