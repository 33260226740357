import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NumberInput from '@common/components/form/inputs/number';
import { ToggleInput } from '@common/components/form/inputs/toggle';
import { Row, Group, TextInput } from '@common/components/form';
import { Message } from '@common/components/form/message';

type Props = {
  enableDescription?: boolean;
};

export const SliderQuestionInput = ({ enableDescription }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Group>
          <TextInput name="parameters.text" placeholder={t('survey:question_form_question_placeholder')} />
        </Group>
      </Row>
      {enableDescription && (
        <Row>
          <Group>
            <TextInput
              name="parameters.description"
              placeholder={t('survey:question_form_description_placeholder')}
              maxLength={160}
            />
          </Group>
        </Row>
      )}
    </>
  );
};

export const SliderQuestionSettings = () => {
  const { t } = useTranslation();
  const [warning, setWarning] = useState('');
  const [error, setError] = useState('');

  return (
    <>
      <Row style={{ lineHeight: '36px', marginBottom: '0' }}>
        <Group flex="1 1 auto">
          {t('survey:question_form_total_steps', { placeholder: '5' })}
        </Group>
        <Group flex="0 0 75px">
          <NumberInput
            name="parameters.amount_of_steps"
            max={10}
            onWarning={setWarning}
            onError={setError}
          />
        </Group>
      </Row>
      <Row>
        <Group flex="1 1 auto;" style={{ justifyContent: 'end', display: 'flex' }}>
          <Message
            warning={warning}
            error={error}
            min={5}
            max={10}
          />
        </Group>
      </Row>
      <Row>
        <Group>
          <ToggleInput
            reversed
            name="parameters.answer_required"
            label={t('survey:question_form_answer_is_required')}
          />
        </Group>
      </Row>
      <Row>
        <Group>
          <TextInput name="parameters.label_left" maxLength={12} />
        </Group>
        <Group>
          <TextInput name="parameters.label_center" maxLength={12} />
        </Group>
        <Group>
          <TextInput name="parameters.label_right" maxLength={12} />
        </Group>
      </Row>
    </>
  );
};
