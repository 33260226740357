import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { useTranslation } from 'react-i18next';

import Icon from '@common/components/icon';
import { QuestionIcon } from '../../components/question-icon';
import { QuestionDropdown } from '../../components/question-dropdown';
import { getQuestionByType } from './questions';

import type { QuestionScreen, ScreenQuestionItem } from '../../../learning/types/objects';
import type { CommonReduxInputProps } from '@common/components/form/inputs/types';
import getValidationByQuestionType from '@modules/survey/validation/get-validation-by-question-type';

type EnabledComponentsArray = React.ComponentProps<typeof QuestionDropdown>['enabledComponents'];

type QuestionInputOwnProps = {
  change: (key: string, value: any) => void;
  enabledComponents: EnabledComponentsArray;
  enableDescription: boolean;
};

type QuestionInputProps = CommonReduxInputProps & QuestionInputOwnProps;

const FormWrapper = ({
  input: { value }, change, enabledComponents, enableDescription,
}: QuestionInputProps) => {
  const { t } = useTranslation();

  const handleChange = (item?: { type: string, parameters?: Record<string, unknown> }) => {
    if (!item) return;

    const { type, parameters } = item;

    change('type', type);

    if (parameters) {
      // Adds default values like options or labels
      Object.keys(parameters).forEach((key) => {
        change(`parameters.${key}`, parameters[key]);
      });
    }
  };

  const content = getQuestionByType(value, { enableDescription });

  if (!content) return null;

  return (
    <div className="Form QuestionForm">
      <div className="QuestionForm__Content">
        <QuestionDropdown
          key={value}
          toggle={(
            <div className="QuestionForm__Type">
              <QuestionIcon type={value} />
              <h3>
                {t('survey:question_type_title', { context: value })}
              </h3>
              <Icon type="arrow_drop_down" />
            </div>
          )}
          enabledComponents={enabledComponents}
          onSelect={handleChange}
        />
        {content.input}
      </div>
      {content.settings && (
        <div className="QuestionForm__Footer">
          {content.settings}
        </div>
      )}
    </div>
  );
};

type QuestionFormData = ScreenQuestionItem;

type QuestionFormOwnProps = {
  screen?: QuestionScreen | null;
  enabledComponents: EnabledComponentsArray;
  enableDescription?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  updateQuestion: (_id: string, data: any) => void;
};

type QuestionFormProps = InjectedFormProps<QuestionFormData, QuestionFormOwnProps, boolean> & QuestionFormOwnProps;

type State = {
  type?: string | null;
};

class QuestionForm extends React.Component<QuestionFormProps, State> {
  static props: QuestionFormProps;

  componentDidMount() {
    const { screen, initialize } = this.props;
    if (!screen?.question) return;

    initialize(screen.components[screen.components.length - 1]);

    if (!screen.question.parameters.text) {
      const input = document.getElementsByClassName('Form__control')[0] as HTMLInputElement;

      if (input) input.focus();
    }
  }

  render() {
    const { change, enabledComponents, enableDescription } = this.props;

    return (
      <Field
        name="type"
        component={FormWrapper}
        change={change}
        enabledComponents={enabledComponents}
        enableDescription={!!enableDescription}
      />
    );
  }
}

export default reduxForm<QuestionFormData, QuestionFormOwnProps, boolean>({
  form: 'question',
  onChange: (newValue, _, { screen, updateQuestion }) => {
    if (updateQuestion && screen) {
      updateQuestion(screen.id, newValue);
    }
  },
  validate: (values) => {
    return getValidationByQuestionType(values);
  },
})(QuestionForm);
