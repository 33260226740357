import React from 'react';
import { useTranslation } from 'react-i18next';

import { ToggleInput } from '@common/components/form/inputs/toggle';
import { TextInput, Row, Group } from '@common/components/form';

type Props = {
  enableDescription?: boolean;
};

export const FileUploadQuestionInput = ({ enableDescription }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Group>
          <TextInput name="parameters.text" placeholder={t('survey:question_form_question_placeholder')} />
        </Group>
      </Row>
      {enableDescription && (
        <Row>
          <Group>
            <TextInput
              name="parameters.description"
              placeholder={t('survey:question_form_description_placeholder')}
              maxLength={160}
            />
          </Group>
        </Row>
      )}
    </>
  );
};

export const FileUploadQuestionSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Group>
          <ToggleInput
            reversed
            helpIcon
            name="parameters.allow_multiple_uploads"
            label={t('survey:question_type_file_upload_allow_multiple_toggle_label')}
          />
        </Group>
      </Row>

      <Row>
        <Group>
          <ToggleInput
            reversed
            helpIcon
            name="parameters.answer_required"
            label={t('survey:question_form_answer_is_required')}
          />
        </Group>
      </Row>
    </>
  );
};
