import React from 'react';

import { EQuestionTypes } from '../../../definitions';
import { TextQuestionInput, TextQuestionSettings } from './text-question';
import { SliderQuestionInput, SliderQuestionSettings } from './slider-question';
import { DateTimeQuestionInput, DateTimeQuestionSettings } from './date-time-question';
import { FileUploadQuestionInput, FileUploadQuestionSettings } from './file-upload-question';
import { NumberQuestionInput, NumberQuestionSettings } from './number-question';
import { MultiItemQuestionInput, MultiItemQuestionSettings } from './multi-item-question';
import { BooleanQuestionInput, BooleanQuestionSettings } from './boolean-question';

import type { ObjectValues } from '@common/types/util-types';

type QuestionContent = {
  input: JSX.Element;
  settings?: JSX.Element;
};

export const getQuestionByType = (
  questionType: ObjectValues<typeof EQuestionTypes>,
  properties: Record<string, any>,
): QuestionContent | undefined => {
  switch (questionType) {
    case EQuestionTypes.TEXT:
      return {
        input: <TextQuestionInput {...properties} />,
        settings: <TextQuestionSettings />,
      };
    case EQuestionTypes.MULTIPLE_CHOICE:
      return {
        input: <MultiItemQuestionInput {...properties} />,
        settings: <MultiItemQuestionSettings />,
      };
    case EQuestionTypes.SLIDER:
      return {
        input: <SliderQuestionInput {...properties} />,
        settings: <SliderQuestionSettings />,
      };
    case EQuestionTypes.DATE_TIME:
      return {
        input: <DateTimeQuestionInput {...properties} />,
        // @ts-expect-error
        settings: <DateTimeQuestionSettings />,
      };
    case EQuestionTypes.NUMBER:
      return {
        input: <NumberQuestionInput {...properties} />,
        settings: <NumberQuestionSettings />,
      };
    case EQuestionTypes.FILE_UPLOAD:
      return {
        input: <FileUploadQuestionInput {...properties} />,
        settings: <FileUploadQuestionSettings />,
      };
    case EQuestionTypes.DROPDOWN:
      return {
        input: <MultiItemQuestionInput {...properties} />,
        settings: <MultiItemQuestionSettings />,
      };
    case EQuestionTypes.BOOLEAN:
      return {
        input: <BooleanQuestionInput {...properties} />,
        settings: <BooleanQuestionSettings />,
      };
    case EQuestionTypes.CHECKLIST:
      return {
        input: <MultiItemQuestionInput {...properties} enableRequired />,
      };
    default:
      return undefined;
  }
};
