import React from 'react';
import { useTranslation, TFunction } from 'react-i18next';

import Dropdown from '@common/components/dropdown';
import { Button } from '@common/components/button';
import { QuestionIcon } from '../question-icon';
import { EQuestionTypes } from '../../definitions';

import type { LooseObject } from '@common/types/util-types';

const getOptions = (t: TFunction) => ({
  [EQuestionTypes.TEXT]: {
    type: EQuestionTypes.TEXT,
  },
  [EQuestionTypes.MULTIPLE_CHOICE]: {
    type: EQuestionTypes.MULTIPLE_CHOICE,
    parameters: {
      options: [{ id: 'temp-1', value: '' }, { id: 'temp-2', value: '' }],
    },
  },
  [EQuestionTypes.SLIDER]: {
    type: EQuestionTypes.SLIDER,
    parameters: {
      amount_of_steps: 5,
    },
  },
  [EQuestionTypes.DATE_TIME]: {
    type: EQuestionTypes.DATE_TIME,
    parameters: {
      allow_date_select: true,
      allow_time_select: true,
    },
  },
  [EQuestionTypes.NUMBER]: {
    type: EQuestionTypes.NUMBER,
  },
  [EQuestionTypes.FILE_UPLOAD]: {
    type: EQuestionTypes.FILE_UPLOAD,
  },
  [EQuestionTypes.DROPDOWN]: {
    type: EQuestionTypes.DROPDOWN,
    parameters: {
      options: [{ id: 'temp-1', value: '' }, { id: 'temp-2', value: '' }],
    },
  },
  [EQuestionTypes.BOOLEAN]: {
    type: EQuestionTypes.BOOLEAN,
    parameters: {
      label_true: t('survey:question_type_boolean_default_value_label_true'),
      label_false: t('survey:question_type_boolean_default_value_label_false'),
    },
  },
  [EQuestionTypes.CHECKLIST]: {
    type: EQuestionTypes.CHECKLIST,
    parameters: {
      options: [{ id: 'temp-1', value: '' }, { id: 'temp-2', value: '' }],
    },
  },
});

type Props = {
  enabledComponents: (keyof ReturnType<typeof getOptions>)[];
  disabled?: boolean;
  toggle?: React.ReactNode,
  onSelect: (item?: LooseObject<{ type: string }>) => void;
};

export const QuestionDropdown = ({ enabledComponents, disabled = false, toggle, onSelect }: Props) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      id="create-question"
      className="CreateQuestion"
      toggle={toggle || (
        <Button type="primary" iconRight="add" disabled={disabled}>
          {t('survey:survey_new_question')}
        </Button>
      )}
    >
      <h3>{t('survey:choose_question_type')}</h3>
      {
        enabledComponents.map((type) => (
          <Dropdown.Item
            className="CreateQuestion__Item"
            onClick={() => onSelect(getOptions(t)[type])}
          >
            <QuestionIcon type={type} />
            <h5>
              {t('survey:question_type_title', { context: type })}
            </h5>
          </Dropdown.Item>
        ))
      }
    </Dropdown>
  );
};
