import React from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { Field, FieldArray } from 'redux-form';

import dndManager from '@common/utils/dnd-manager';
import { ToggleInput } from '@common/components/form/inputs/toggle';
import { Group, Row, TextInput } from '@common/components/form';
import { Button } from '@common/components/button';
import { Icon } from '@common/components/icon';
import { combineClassNames } from '@utils/combineClassNames';

import type { FieldArrayReduxInputProps, FieldArrayReduxWrappedField } from '@common/components/form/inputs/types';
import type { MultipleChoiceOption } from '../../../../../learning/types/objects';

type MultiItemQuestionItemOwnProps = {
  item: MultipleChoiceOption;
  optionIndex: number;
  onChangeOrder: (grabbedId: string, draggedToId: string) => void;
  onRemove: () => void;
  enableRequired?: boolean;
};

type ExtraProperties = {
  enableDescription?: boolean;
  enableRequired?: boolean;
};

let previousUid: null | string = null;

export const MultiItemQuestionItem = ({
  item,
  optionIndex,
  onChangeOrder,
  onRemove,
  enableRequired,
}: MultiItemQuestionItemOwnProps) => {
  const [, dropRef] = useDrop({
    accept: ['dropdown-option'],
    hover: (source: MultipleChoiceOption) => {
      const uid = `${source.id}-${item.id}`;

      if (source.id === item.id || uid === previousUid) return;

      onChangeOrder(source.id, item.id);
      previousUid = uid;
    },
  });

  const [{ isDragging }, dragRef] = useDrag({
    type: 'dropdown-option',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const fullClassName = combineClassNames('MultiItemQuestionItem', {
    'MultiItemQuestionItem--is-dragging': isDragging,
  });

  const dragHandleFullClassName = combineClassNames('MultiItemQuestionItem__DragHandle', {
    'MultiItemQuestionItem__DragHandle--is-dragging': isDragging,
  });

  return (
    <div className={fullClassName} ref={(ref) => dragRef(dropRef(ref))}>
      <Icon type="drag_handle" className={dragHandleFullClassName} />
      <TextInput name={`parameters.options[${optionIndex}].value`} />
      {/* <Button iconRight="add" onClick={onAddNewHere} /> */}
      {enableRequired && (
        <Field
          name={`parameters.options[${optionIndex}].required`}
          // @ts-expect-error
          component={({ input: { value, onChange } }) => (
            <Button
              icon="required"
              size="large"
              className="MultiItemQuestionItem__Required"
              active={value}
              // See https://github.com/redux-form/redux-form/issues/860#issuecomment-625254444
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => onChange(!value)}
            />
          )}
        />
      )}
      <Button icon="delete" size="large" onClick={onRemove} />
    </div>
  );
};

export const UnconnectedMultiItemQuestionInput = ({
  fields,
  enableRequired,
  enableDescription,
}: FieldArrayReduxInputProps<MultipleChoiceOption> & ExtraProperties) => {
  const { t } = useTranslation();

  const genNewEmptyOption = () => ({
    value: '',
    id: `temp-${fields.getAll().filter(({ id }) => id.includes('temp')).length + 1}`,
  });

  const handleChangeOrder = (grabbedId: string, draggedToId: string) => {
    const currValues = fields.getAll();
    const grabbedIndex = currValues.findIndex(({ id }) => id === grabbedId);
    const dropIndex = currValues.findIndex(({ id }) => id === draggedToId);

    fields.swap(grabbedIndex, dropIndex);
  };

  return (
    <div className="MultiItemQuestionInput MultiItemQuestionInput--draggable">
      <Row>
        <Group>
          <TextInput name="parameters.text" placeholder={t('survey:question_form_question_placeholder')} />
        </Group>
      </Row>
      {enableDescription && (
        <Row>
          <Group>
            <TextInput
              name="parameters.description"
              placeholder={t('survey:question_form_description_placeholder')}
              maxLength={160}
            />
          </Group>
        </Row>
      )}

      <span className="MultiItemQuestionInput__ItemsLabel">
        {t('survey:question_type_dropdown_items_header')}
      </span>

      <div className="MultiItemQuestionInput__Items">
        <DndProvider manager={dndManager}>
          {fields.getAll().map((option, index) => (
            <MultiItemQuestionItem
              key={option.id}
              enableRequired={enableRequired}
              item={option}
              optionIndex={index}
              onChangeOrder={handleChangeOrder}
              onRemove={() => fields.remove(index)}
            />
          ))}
        </DndProvider>
      </div>

      <Button className="MultiItemQuestionInput__AddItemButton" onClick={() => fields.push(genNewEmptyOption())}>
        <Icon type="add" />
        <span>{t('survey:question_type_dropdown_add_option_button')}</span>
      </Button>
    </div>
  );
};

export const MultiItemQuestionInput = (props: Omit<FieldArrayReduxWrappedField, 'name'> & ExtraProperties) => (
  <FieldArray
    {...props}
    name="parameters.options"
    component={UnconnectedMultiItemQuestionInput}
    props={undefined} // fixes TS error...
  />
);

export const MultiItemQuestionSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Group>
          <ToggleInput
            helpIcon
            reversed
            name="parameters.answer_required"
            label={t('survey:question_form_answer_is_required')}
          />
        </Group>
      </Row>

      <Row>
        <Group>
          <ToggleInput
            helpIcon
            reversed
            name="parameters.allow_multiple_options"
            label={t('survey:question_form_are_multiple_selections_allowed')}
          />
        </Group>
      </Row>
    </>
  );
};
